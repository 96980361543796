<template>
  <div></div>
</template>

<script>
  import creditApis from '@/api/creadit'
  import { Dialog, Toast } from 'vant'

  export default {
    name: 'faceVerifying',
    data() {
      return {
        query: {},
        creditMsg: undefined,
      }
    },
    created() {
      this.creditMsg = this.$store.state.creditMsg
      this.getQuery()
      this.updateH5FaceResult()
    },
    computed: {
      // creditMsg() {
      //   return this.$store.state.creditMsg
      // },
    },

    methods: {
      getQuery() {
        const { query } = this.$route
        this.query = query
      },
      async updateH5FaceResult() {
        try {
          // const { userId } = this.creditMsg
          // if (!userId) return
          this.$toast.loading({
            message: '处理中',
            duration: 20000,
          })
          const model = {
            ...this.query,
            // userIdE: userId,
          }
          const res = await creditApis.updateH5FaceResult(model)
          this.$router.replace({
            name: 'authenticationSuccess',
          })
        } catch (error) {
          console.error(error)
          this.$router.replace({
            name: 'authenticationError',
            query: {
              type: 'face',
            },
          })
        } finally {
          this.$toast.clear()
        }
      },
    },
  }
</script>

<style lang="less" scoped></style>
